<template>
  <section class="dtc-main-section">
    <vue-tabs v-model="tabName" @tab-change="handleTabChange">
      <v-tab
        v-for="(item, i) in allUserRight"
        :title="item.text"
        :key="item + i"
      >
        <Apply9 v-if="item.text == '第一階段資格合格名單'" ></Apply9>
        <ApplyBachelor v-if="item.text == '第一階段合格名單(學士)'" ></ApplyBachelor>
        <Apply14 v-if="item.text == '甄選成績上傳作業'"></Apply14>
        <Apply15 v-if="item.text == '甄選學測成績排名查詢'"></Apply15>
        <Apply23_3 v-if="item.text == '公費生成績查詢'"></Apply23_3>
        <ApplyScoreBachelor v-if="item.text == '公費生成績查詢(學士)'"></ApplyScoreBachelor>
      </v-tab>
    </vue-tabs>
  </section>
</template>

<script>
import { VueTabs, VTab } from "vue-nav-tabs";
import { store } from "@/store/global.js";
import "vue-nav-tabs/themes/vue-tabs.css";
import queryString from "query-string";
import { domainObject } from "@/constant.js";
import Apply9 from "./DtcVueTabs103/Apply9";
import Apply14 from "./DtcVueTabs103/Apply14";
import Apply15 from "./DtcVueTabs103/Apply15";
import Apply23_3 from "./DtcVueTabs102/Apply7";
import ApplyBachelor from "./DtcVueTabs103/ApplyBachelor";
import ApplyScoreBachelor from "./DtcVueTabs103/ApplyScoreBachelor";

const urlPost = "applyform1/Post";
const urlPut = "applyform1/Put";
const urlGet = "applyform1/Get?Identifier=";
const zero = "T00:00:00";

const titles = [
  "第一階段資格合格名單",
  "第一階段合格名單(學士)",
  "甄選成績上傳作業",
  "甄選學測成績排名查詢",
  "公費生成績查詢",
  "公費生成績查詢(學士)",
];

export default {
  name: "totalSpendTabsVue",
  data() {
    return {
      titles,
      showTab1: false,
      show: true,
      tabName: "",
      allUserRight: [
        { text: "第一階段資格合格名單", isActive: false, functionId: "10011" },
        { text: "第一階段合格名單(學士)", isActive: false, functionId: "10111" },
        { text: "甄選成績上傳作業", isActive: false, functionId: "10012" },
        { text: "甄選學測成績排名查詢", isActive: false, functionId: "10013" },
        { text: "公費生成績查詢", isActive: false, functionId: "10018" },
        { text: "公費生成績查詢(學士)", isActive: false, functionId: "10118" },
      ],
      userRights: [],
      domainObject,
    };
  },
  components: {
    VueTabs,
    VTab,
    Apply9,
    Apply14,
    Apply15,
    Apply23_3,
    ApplyBachelor,
    ApplyScoreBachelor,
  },
  methods: {
    handleTabChange(tabIndex, newTab, oldTab) {
      if (tabIndex == 0) {
        this.$root.$emit("get-Newest-LocalstuData");
      } else if (tabIndex == 1) {
        this.$root.$emit("get-Newest-Notregister");
      }
    },
    setUserRight() {
      let arr = Boolean(sessionStorage.functionIds)
        ? JSON.parse(sessionStorage.getItem("functionIds"))
        : [];
      this.allUserRight = this.allUserRight.map((s) => {
        s.isActive = arr.find((k) => k == s.functionId) ? true : false;
        return s;
      });
      this.allUserRight = this.allUserRight.filter((s) => s.isActive);
      //   let allData = (this.allData = JSON.parse(localStorage.dtxBuget));
      //   allData = allData.find((s) => s.MenuName == "系統管理")
      //     ? allData.find((s) => s.MenuName == "系統管理")
      //     : {};
      //   if (Boolean(allData.SubMenu)) {
      //     this.allUserRight = !allData.SubMenu.length
      //       ? []
      //       : allData.SubMenu.find((s) => s.MenuName == "在地養成資料維護");
      //     if (this.allUserRight.SubMenu.length) {
      //       this.allUserRight.SubMenu.forEach((s) => {
      //         this.userRights.push(s.MenuName);
      //       });
      //     }
      //   }
    },
  },
  watch: {},
  async mounted() {
    // setTimeout(() => {
    //   this.showTab1 = true;
    //   this.show = false;
    // }, 0);

    this.setUserRight();
  },
  beforeMount() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.dtc-main-section {
  width: fit-content;
  //   height: 100vh;
  //   overflow-x: hidden;
}
iframe {
  width: 100vw;
  height: clamp(60vh, 90vh, 100vh);
}
</style>
